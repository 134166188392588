<script>
  export default {
    props: {
      color: {
        type: String,
      },
    },
  }
</script>

<template>
  <div
    class="stone"
    :class="color"
  ></div>
</template>

<style scoped>
  .stone {
    width: 90%;
    aspect-ratio: 1;
    border-radius: 50% 80% 50% 100%;
    box-shadow: 5px 5px 2px #382a1d99;
  }

  .dark {
    background: #070502;
  }

  .light {
    background: #decbb1;
  }
</style>
