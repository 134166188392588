<script>
  export default {
    components: {
      grid: require("./grid").default,
    },

    data() {
      return {

      };
    },
  }
</script>

<template>
  <div>
    <div class="grids">
      <grid
        color="dark"
      ></grid>

      <grid
        color="light"
      ></grid>

      <grid
        color="dark"
      ></grid>

      <grid
        color="light"
      ></grid>
    </div>
  </div>
</template>

<style scoped>
  .grids {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-height: 80vh;
    aspect-ratio: 1;
    grid-gap: 3vh;
  }
</style>
