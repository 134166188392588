<script>
  /* TODO
    Total Score Level of Depression
    No Depression 0-5
    Normal but unhappy 6-10
    Mild depression 11-25
    Moderate depression 26-50
    Severe depression 51-75
    Extreme depression 76-100

    - save scores w/ date
      3/17: {"0":1,"1":3,"2":1,"3":2,"4":1,"5":1,"6":1,"7":1,"8":2,"9":1,"10":4,"11":1,"12":4,"13":2,"14":4,"15":3,"16":2,"17":1,"18":1,"19":0,"20":4,"21":1,"22":1,"23":0,"24":0}
      3/25: {"0":1,"1":1,"2":0,"3":1,"4":1,"5":1,"6":0,"7":1,"8":2,"9":1,"10":4,"11":1,"12":1,"13":2,"14":3,"15":2,"16":2,"17":2,"18":2,"19":0,"20":3,"21":1,"22":1,"23":0,"24":0}
      4/1: {"0":1,"1":2,"2":0,"3":2,"4":1,"5":1,"6":1,"7":2,"8":3,"9":1,"10":3,"11":1,"12":1,"13":1,"14":2,"15":2,"16":1,"17":0,"18":0,"19":0,"20":0,"21":1,"22":1,"23":0,"24":0}
      4/8: {"0":1,"1":1,"2":0,"3":1,"4":1,"5":1,"6":0,"7":1,"8":2,"9":0,"10":2,"11":0,"12":0,"13":1,"14":1,"15":1,"16":1,"17":0,"18":0,"19":1,"20":0,"21":0,"22":1,"23":0,"24":0}
      4/15: {"0":1,"1":1,"2":0,"3":0,"4":0,"5":1,"6":1,"7":1,"8":2,"9":0,"10":2,"11":0,"12":0,"13":1,"14":1,"15":1,"16":1,"17":0,"18":0,"19":0,"20":1,"21":1,"22":1,"23":0,"24":0}
      4/22: {"0":2,"1":2,"2":0,"3":1,"4":1,"5":2,"6":1,"7":2,"8":3,"9":0,"10":1,"11":1,"12":0,"13":2,"14":1,"15":1,"16":2,"17":2,"18":1,"19":0,"20":1,"21":1,"22":1,"23":0,"24":0}
      4/29: {"0":2,"1":1,"2":1,"3":1,"4":0,"5":1,"6":1,"7":0,"8":2,"9":0,"10":1,"11":1,"12":0,"13":2,"14":1,"15":1,"16":2,"17":0,"18":0,"19":0,"20":1,"21":1,"22":1,"23":0,"24":0}
      5/7: {"0":1,"1":1,"2":0,"3":1,"4":0,"5":1,"6":1,"7":0,"8":2,"9":0,"10":1,"11":1,"12":0,"13":0,"14":1,"15":1,"16":0,"17":0,"18":0,"19":0,"20":0,"21":1,"22":1,"23":0,"24":0}
      5/13: {"0":1,"1":3,"2":1,"3":3,"4":2,"5":1,"6":1,"7":1,"8":4,"9":1,"10":1,"11":1,"12":0,"13":2,"14":4,"15":1,"16":1,"17":1,"18":3,"19":1,"20":1,"21":1,"22":1,"23":0,"24":0}
      5/20: {"0":1,"1":1,"2":2,"3":1,"4":0,"5":0,"6":1,"7":1,"8":3,"9":0,"10":1,"11":1,"12":0,"13":1,"14":2,"15":2,"16":1,"17":1,"18":2,"19":1,"20":1,"21":1,"22":1,"23":0,"24":0}
      5/28: {"0":1,"1":1,"2":1,"3":2,"4":0,"5":2,"6":2,"7":1,"8":2,"9":0,"10":1,"11":1,"12":1,"13":1,"14":2,"15":2,"16":1,"17":0,"18":1,"19":0,"20":1,"21":1,"22":1,"23":0,"24":0}
      6/4: {"0":1,"1":1,"2":1,"3":1,"4":0,"5":1,"6":0,"7":1,"8":2,"9":0,"10":0,"11":0,"12":0,"13":0,"14":2,"15":2,"16":0,"17":1,"18":1,"19":0,"20":1,"21":1,"22":1,"23":0,"24":0}
      6/10: {"0":0,"1":0,"2":0,"3":0,"4":0,"5":1,"6":1,"7":1,"8":2,"9":1,"10":1,"11":0,"12":0,"13":1,"14":2,"15":1,"16":0,"17":1,"18":1,"19":0,"20":0,"21":0,"22":1,"23":0,"24":0}
      6/17: {"0":1,"1":1,"2":1,"3":2,"4":1,"5":2,"6":1,"7":1,"8":3,"9":0,"10":1,"11":1,"12":1,"13":1,"14":2,"15":2,"16":1,"17":0,"18":0,"19":0,"20":0,"21":1,"22":1,"23":0,"24":0}
      6/24: {"0":1,"1":1,"2":1,"3":1,"4":0,"5":1,"6":0,"7":0,"8":2,"9":1,"10":1,"11":1,"12":1,"13":0,"14":2,"15":2,"16":1,"17":1,"18":0,"19":0,"20":0,"21":1,"22":1,"23":0,"24":0}
      7/1: {"0":1,"1":1,"2":0,"3":2,"4":1,"5":1,"6":0,"7":0,"8":3,"9":1,"10":1,"11":1,"12":1,"13":2,"14":3,"15":2,"16":2,"17":1,"18":0,"19":0,"20":1,"21":0,"22":1,"23":0,"24":0}
      7/9: {"0":2,"1":2,"2":0,"3":2,"4":0,"5":1,"6":1,"7":0,"8":3,"9":0,"10":1,"11":1,"12":1,"13":1,"14":2,"15":2,"16":1,"17":1,"18":1,"19":0,"20":0,"21":1,"22":1,"23":0,"24":0}
      7/15: {"0":1,"1":1,"2":0,"3":1,"4":0,"5":1,"6":0,"7":0,"8":3,"9":1,"10":1,"11":0,"12":1,"13":1,"14":2,"15":2,"16":1,"17":1,"18":1,"19":0,"20":0,"21":1,"22":0,"23":0,"24":0}
      7/29: {0":0,"1":0,"2":0,"3":1,"4":0,"5":1,"6":0,"7":1,"8":1,"9":1,"10":0,"11":0,"12":0,"13":0,"14":2,"15":1,"16":0,"17":2,"18":2,"19":1,"20":0,"21":1,"22":1,"23":0,"24":0}
      8/5: {"0":0,"1":0,"2":0,"3":1,"4":0,"5":1,"6":1,"7":1,"8":2,"9":0,"10":1,"11":0,"12":0,"13":0,"14":2,"15":2,"16":0,"17":1,"18":1,"19":1,"20":0,"21":1,"22":0,"23":0,"24":0}
      8/12: {"0":0,"1":0,"2":0,"3":1,"4":0,"5":1,"6":1,"7":0,"8":2,"9":1,"10":1,"11":0,"12":0,"13":1,"14":2,"15":2,"16":1,"17":1,"18":2,"19":0,"20":0,"21":1,"22":1,"23":0,"24":0}
      8/26: {"0":1,"1":1,"2":0,"3":0,"4":0,"5":1,"6":0,"7":1,"8":2,"9":0,"10":1,"11":0,"12":0,"13":0,"14":2,"15":2,"16":1,"17":2,"18":0,"19":1,"20":0,"21":0,"22":0,"23":0,"24":0}
      9/2: {"0":1,"1":2,"2":0,"3":1,"4":0,"5":1,"6":1,"7":0,"8":4,"9":0,"10":1,"11":0,"12":1,"13":0,"14":2,"15":2,"16":1,"17":0,"18":0,"19":0,"20":1,"21":1,"22":0,"23":0,"24":0}
      9/10: {"0":1,"1":1,"2":0,"3":0,"4":0,"5":1,"6":1,"7":1,"8":2,"9":0,"10":0,"11":0,"12":0,"13":0,"14":2,"15":2,"16":0,"17":2,"18":0,"19":1,"20":0,"21":0,"22":0,"23":0,"24":0}
      9/16: {"0":0,"1":1,"2":0,"3":2,"4":1,"5":0,"6":1,"7":1,"8":2,"9":1,"10":0,"11":0,"12":0,"13":1,"14":2,"15":1,"16":0,"17":1,"18":1,"19":1,"20":0,"21":1,"22":0,"23":0,"24":0}
      10/1: {"0":0,"1":1,"2":0,"3":1,"4":0,"5":2,"6":1,"7":1,"8":2,"9":0,"10":1,"11":0,"12":0,"13":2,"14":2,"15":1,"16":1,"17":2,"18":1,"19":0,"20":1,"21":0,"22":0,"23":0,"24":0}
      10/7: {"0":1,"1":2,"2":0,"3":2,"4":0,"5":0,"6":0,"7":1,"8":3,"9":1,"10":2,"11":0,"12":1,"13":0,"14":3,"15":2,"16":1,"17":0,"18":1,"19":0,"20":1,"21":1,"22":0,"23":0,"24":0}
      10/14: {"0":1,"1":1,"2":1,"3":2,"4":0,"5":1,"6":3,"7":1,"8":2,"9":1,"10":2,"11":1,"12":2,"13":2,"14":3,"15":2,"16":2,"17":3,"18":1,"19":3,"20":3,"21":1,"22":0,"23":0,"24":0}
      10/23: {"0":1,"1":1,"2":1,"3":1,"4":0,"5":1,"6":2,"7":2,"8":2,"9":0,"10":1,"11":0,"12":1,"13":1,"14":2,"15":2,"16":1,"17":3,"18":1,"19":1,"20":2,"21":1,"22":0,"23":0,"24":0}
      10/29: {"0":1,"1":1,"2":0,"3":2,"4":0,"5":1,"6":1,"7":1,"8":2,"9":1,"10":0,"11":0,"12":0,"13":1,"14":2,"15":1,"16":1,"17":0,"18":0,"19":0,"20":1,"21":1,"22":0,"23":0,"24":0}
      11/5: {"0":1,"1":1,"2":0,"3":1,"4":0,"5":1,"6":1,"7":0,"8":1,"9":1,"10":0,"11":0,"12":0,"13":0,"14":2,"15":1,"16":1,"17":0,"18":0,"19":0,"20":1,"21":1,"22":0,"23":0,"24":0}
      11/11: {"0":1,"1":1,"2":1,"3":1,"4":0,"5":1,"6":0,"7":0,"8":1,"9":1,"10":0,"11":0,"12":0,"13":1,"14":2,"15":2,"16":0,"17":0,"18":1,"19":0,"20":1,"21":1,"22":0,"23":0,"24":0}
      12/9: {"0":1,"1":1,"2":0,"3":0,"4":0,"5":1,"6":1,"7":1,"8":0,"9":0,"10":1,"11":0,"12":0,"13":1,"14":2,"15":2,"16":1,"17":2,"18":1,"19":1,"20":1,"21":1,"22":0,"23":0,"24":0}
      12/26: {"0":1,"1":1,"2":1,"3":0,"4":0,"5":0,"6":0,"7":0,"8":2,"9":0,"10":1,"11":0,"12":0,"13":1,"14":2,"15":2,"16":0,"17":1,"18":2,"19":1,"20":0,"21":1,"22":0,"23":0,"24":0}
    - show history of scores
  */
  const questions = [
    "Feeling sad or down in the dumps",
    "Feeling unhappy or blue",
    "Crying spells or tearfulness",
    "Feeling discouraged",
    "Feeling hopeless",
    "Low self esteem",
    "Feeling worthless or inadequate",
    "Guilt or shame",
    "Criticizing yourself or blaming others",
    "Difficulty making decisions",
    "Loss of interest in family, friends or colleagues",
    "Loneliness",
    "Spending less time with family or friends",
    "Loss of motivation",
    "Loss of interest in work or other activities",
    "Avoiding work or other activities",
    "Loss of pleasure or satisfaction in life",
    "Feeling tired",
    "Difficulty sleeping or sleeping too much",
    "Decreased or increased appetite",
    "Loss of interest in sex",
    "Worrying about your health",
    "Do you have any suicidal thoughts?",
    "Would you like to end your life?",
    "Do you have a plan for harming yourself?",
  ];

  const ratings = [
    "Not at all",
    "Somewhat",
    "Moderately",
    "A lot",
    "Extremely",
  ];

  export default {
    data() {
      return {
        questions,
        ratings,
        input: {},
      };
    },

    computed: {
      sum() {
        let sum = 0;

        Object.keys(this.input)
          .forEach(k => {
            sum += this.input[k];
          });

        return sum;
      }
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col class="text-center my-6">
        Indicate how much you have experienced each symptom during the past week, including today.
      </v-col>
    </v-row>

    <v-row
      v-for="(q, i) in questions"
      :key="i"
    >
      <v-col
        cols="12"
        lg="10"
        offset-lg="1"
      >
        <v-sheet elevation="10" class="py-6 px-4">
          <div class="text-center text-h6">
            {{ q }}
          </div>

          <div class="d-flex align-center justify-center">
            <v-radio-group
              v-model="input[i]"
              :row="['xs'].indexOf($vuetify.breakpoint.name) == -1"
            >
              <v-radio
                v-for="(r, j) in ratings"
                :key="j"
                :value="j"
                class="mx-5"
              >
                <template v-slot:label>
                  <div
                    :class="input[i] === j ? 'primary--text' : ''"
                  >
                    {{ r }}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="10"
        offset-lg="1"
      >
        <v-sheet
          elevation="10"
          class="py-6 px-4"
        >
          <template v-if="Object.keys(input).length < questions.length">
            <v-alert
              color="red"
              dark
              class="mt-3"
            >
              Not all questions have been answered
            </v-alert>
          </template>

          <div
            class="text-center text-h4"
          >
            {{ sum }}
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
