<script>
  export default {
    components: {
      game: require("./game").default,
    },

    data() {
      return {
      };
    },
  }
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="8" offset="2"
          md="6" offset-md="3"
          lg="4" offset-lg="4"
        >
          <game></game>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
