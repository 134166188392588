<script>
  export default {
    components: {
      stone: require("./stone").default,
    },

    props: {
      color: {
        type: String,
      },
    },

    data() {
      return {
      };
    },
  }
</script>

<template>
  <div
    class="grid"
    :class="color"
  >
    <template v-for="y in 4">
      <template v-for="x in 4">
        <div
          class="cell"
          :key="'_' + x + y"
        >
          <template v-if="[1,4].indexOf(y) > -1">
            <stone
              :color="y == 4 ? 'dark' : 'light'"
            ></stone>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped>
/*
Black: #070502
Mocha: #382a1d
Caramel: #8f614b
Heavy Cream: #b69b7d
Whip: #decbb1
*/
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    aspect-ratio: 1;
    border-radius: 5%;
    overflow: hidden;
  }

  .cell {
    border: 1px solid #382a1d20;
    border-radius: 10%;
    margin: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grid.dark {
    background-color:  #8f614b;
  }

  .grid.light {
    background-color: #b69b7d;
  }
</style>
