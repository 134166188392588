export default {
  routes: [
    {
      name: "home",
      path: "*",
      pushPath: "/",
      component: require("./components/Home").default,
      meta: {
        icon: "mdi-home",
        color: "green",
      },
    },

    {
      name: "me",
      path: "/me",
      component: require("./components/Me").default,
      meta: {
        icon: "mdi-account-circle",
        color: "blue-grey",
      },
    },

    {
      name: "set",
      path: "/set/",
      component: require("./components/Set/Set").default,
      meta: {
        icon: "mdi-cards",
        color: "cyan",
      },
      children: [
        {
          name: "set/lobby",
          path: "",
          component: require("./components/Set/Lobby").default,
        },
        {
          name: "set/game",
          path: "game",
          component: require("./components/Set/Game").default,
        },
      ]
    },

    {
      name: "wordle",
      path: "/wordle",
      component: require("./components/Wordle/index").default,
      meta: {
        icon: "mdi-progress-question",
        color: "light-blue",
      },
    },

    {
      name: "mastermind",
      path: "/mastermind",
      component: require("./components/Mastermind/index").default,
      meta: {
        icon: "mdi-brain",
        color: "light-blue",
      },
      children: [
        {
          name: "mastermind/sp",
          path: "sp",
          component: require("./components/Mastermind/single-player").default,
        },
        {
          name: "mastermind/mp",
          path: "mp",
          component: require("./components/Mastermind/multi-player").default,
        },
      ],
    },

    {
      name: "shobu",
      path: "/shobu",
      component: require("./components/shobu/index").default,
      meta: {
        icon: "mdi-mirror",
        color: "brown",
      },
    },

    {
      name: "fingers",
      path: "/fingers",
      component: require("./components/Finger/index").default,
      meta: {
        icon: "mdi-cursor-pointer",
        color: "grey",
      },
      children: [
        {
          name: "fingers/random",
          path: "random",
          component: require("./components/Finger/random-single").default,
        },
        {
          name: "fingers/reflex",
          path: "reflex",
          component: require("./components/Finger/reflex").default,
        },
      ]
    },

    {
      name: "slide-puzzle",
      path: "/slide-puzzle",
      component: require("./components/SlidePuzzle/index").default,
      meta: {
        icon: "mdi-puzzle",
        color: "amber",
      },
    },

    {
      name: "picross",
      path: "/picross",
      component: require("./components/Picross/Picross").default,
      meta: {
        hidden: true,
        icon: "mdi-view-grid",
        color: "teal",
      },
    },

    {
      name: "io",
      path: "/io",
      component: require("./components/IO/index").default,
      meta: {
        icon: "mdi-power-standby",
        color: "light-green",
      },
    },

    {
      name: "qr",
      path: "/qr",
      component: require("./components/qr/index").default,
      meta: {
        icon: "mdi-qrcode",
        color: "lime",
      },
    },

    {
      name: "fg",
      path: "/fg",
      component: require("./components/fg/index").default,
      meta: {
        hidden: true,
        icon: "mdi-head-heart",
        color: "green",
      },
    },

    {
      name: "image-playground",
      path: "/image-playground",
      component: require("./components/ImagePlayground/index").default,
      meta: {
        hidden: true,
        icon: "mdi-palette-advanced",
        color: "light-blue",
      },
    },

    {
      name: "snack-timer",
      path: "/snack-timer",
      component: require("./components/SnackTimer/index").default,
      meta: {
        hidden: true,
        icon: "mdi-timer-sand",
        color: "green",
      },
    },

    {
      name: "collatz",
      path: "/collatz",
      component: require("./components/Collatz/index").default,
      meta: {
        hidden: true,
        icon: "mdi-counter",
        color: "blue",
      },
    },

    {
      name: "countdown",
      path: "/countdown",
      component: require("./components/Countdown/index").default,
      meta: {
        hidden: true,
        icon: "mdi-counter",
        color: "light-blue",
      },
    },

    {
      name: "misc",
      path: "/misc",
      component: require("./components/Misc/index").default,
      meta: {
        hidden: true,
        icon: "mdi-rhombus-split",
        color: "red",
      },
    },

    {
      name: "clock",
      path: "/clock",
      component: require("./components/Clock/index").default,
      meta: {
        hidden: true,
        icon: "mdi-clock",
        color: "light-green",
      },
    },

    {
      name: "phaser",
      path: "/phaser",
      component: require("./components/Phaser/index").default,
      meta: {
        hidden: true,
        icon: "mdi-laser-pointer",
        color: "purple",
      },
    },

    {
      name: "beepboop",
      path: "/beepboop",
      component: require("./components/BeepBoop/index").default,
      meta: {
        hidden: true,
        icon: "mdi-robot-outline",
        color: "green",
      },
    },

    {
      name: "life",
      path: "/life",
      component: require("./components/Life/index").default,
      meta: {
        hidden: true,
        icon: "mdi-grid",
        color: "deep-orange",
      },
    },

    {
      name: "drag",
      path: "/drag",
      component: require("./components/DragAndDrop/index").default,
      meta: {
        hidden: true,
        icon: "mdi-drag-variant",
        color: "teal",
      },
    },

    {
      name: "colors",
      path: "/colors",
      component: require("./components/Colors/index").default,
      meta: {
        hidden: true,
        icon: "mdi-palette",
        color: "red",
      },
    },

    {
      name: "icons",
      path: "/icons",
      component: require("./components/Icons/index").default,
      meta: {
        hidden: true,
        icon: "mdi-emoticon-cool-outline",
        color: "amber",
      },
    },

    {
      name: "storage",
      path: "/storage",
      component: require("./components/Storage/index").default,
      meta: {
        hidden: true,
        icon: "mdi-file-cloud-outline",
        color: "blue-grey",
      },
    },

    {
      name: "animejs",
      path: "/animejs",
      component: require("./components/Animejs/index").default,
      meta: {
        hidden: true,
        icon: "mdi-motion",
        color: "pink",
      },
    },

    {
      name: "phone-pad",
      path: "/phone-controller",
      component: require("./components/PhoneController/index").default,
      meta: {
        hidden: true,
        icon: "mdi-controller-classic",
        color: "red",
      },
      children: [
        {
          name: "client",
          path: "client/:socketid/:state?",
          component: require("./components/PhoneController/Client").default
        },
      ]
    },

    {
      name: "a-frame",
      path: "/a-frame",
      component: require("./components/a-frame/index").default,
      meta: {
        hidden: true,
        icon: "mdi-virtual-reality",
        color: "orange",
      },
    },

    {
      name: "history",
      path: "/history",
      component: require("./components/History").default,
      meta: {
        icon: "mdi-history",
        color: "brown",
      },
    },
  ],
}
