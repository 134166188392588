import { render, staticRenderFns } from "./stone.vue?vue&type=template&id=0251bce7&scoped=true&"
import script from "./stone.vue?vue&type=script&lang=js&"
export * from "./stone.vue?vue&type=script&lang=js&"
import style0 from "./stone.vue?vue&type=style&index=0&id=0251bce7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0251bce7",
  null
  
)

export default component.exports